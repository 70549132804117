.admin {
  background-color: #f5f5f5;
}
.adminDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:70vh;
  height: 100%;
  /* padding-bottom: 100px; */
}
.loginBox{
    display: flex;
    border: 1px solid #111;
    flex-direction: column;
    align-items: center;
    width:30%;
    border-radius: 5px;
    padding: 10px;
}

.inputBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    margin-top: 20px;
}
.inputBox > div{
    width:100%;
}
.inputBox > div label{
    font-size: 18px;
}
.adminInputs{
    background: #fff;
    margin-bottom: 20px;
    border: 1px solid gray;
}
.adminFormBtn{
    background-color: #FFB84C;
    border: none;
    width: 150px;
    height:40px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

@media (max-width:568px){
    .loginBox{        
        width:100%; 
    }
    .adminDiv {
       
        min-height:90vh;
        height: 100%;
      }
}