* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
 
}
::-webkit-scrollbar {display:none;}