.preloader{
    height: 100vh;
    width: 100%;
    background-color: #111;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.preloader .texts-containerr{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.preloader .texts-container{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    /* height: 60px; */
    /* width: 280px; */
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    color: #fff;   
}
.preloader .texts-containerr img{
    width: 200px;
}