a {
  text-decoration: none;
  color: none;
}
.anyquery{
  color: #fff;
}
.videoSection {
  position: relative;
  margin-top: 70px;
}
.video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
  filter: brightness(30%);
}
.hero-header {
  position: relative;
  z-index: -10;
  background-repeat: no-repeat;
  background-color: #111;
  /* background-image: linear-gradient(180deg, #232526, #414345 90%, #4a514c); */
  opacity: 1;
}
.hero-header .patter-hero {
  background-image: url("https://uploads-ssl.webflow.com/5f0d69c3898b075ba3c70bd6/608a55c1231e7d31e2f40767_BG-pattern.svg");
  position: absolute;
  background-size: cover;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  z-index: -9;
  opacity: 0.2;
}
.home-text {
  padding: 100px 60px;
  z-index: 99;
}
.banner-text {
  padding: 50px 0;
}
.banner-text h1 {
  font-size: 60px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.banner-text p {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.singlestrip {
  display: flex;
  justify-content: center;
  background-color: gray;
  align-items: center;
  text-align: center;
}
.singlestrip p {
  margin: 0;
  padding: 5px 0;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
}

/* Home-card */
.home-cards {
  background-color: #f3f4f6;
  margin-top: 0px;
  padding-top: 4rem;
  padding-bottom: 4.5rem;
}
.home-card {
  background-color: #fff;
  min-height: 350px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.home-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.home-card img {
  width: 100%;
  height: 200px !important;
}
.home-card-text {
  padding: 10px 15px;
  min-height: 200px;
}

.home-card .home-card-title p {
  margin: 10px 0 5px 0;
  font-size: 18px;
  color: #111;
}

.home-cards .home-card-desc span {
  color: gray;
  font-weight: 300;
}
.home-card-link {
  position: absolute;
  bottom: -20px;
}
.home-card-link p {
  padding: 10px 0 20px 0;
  color: #107c5d;
}
.home-card-link p a{
  color: #00b67a;
}

.branding {
  position: relative;
  background-attachment: fixed;
  background-image: url("https://res.cloudinary.com/dqlx1vyne/image/upload/v1684842091/fprintresort/branding_dyumep.webp");
  background-position: center top;
  background-size: cover;
  height: 70vh;
  width: 100%;
  padding: 0;
}
.branding .branding-overlay {
  position: absolute;
  background: rgba(45, 44, 44, 0.58);
  height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -99;
}

.branding,
.branding-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  font-family: "Pacifico", cursive;
  color: lightgray;
}
.branding-text p {
  font-size: 30px;
  margin: 5px;
}
.branding-text h1 {
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.branding-text span {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.brand-btn {
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 30px;
  border-radius: 50px;
  font-family: Arial, Helvetica, sans-serif;
}

/* Our Services */
.home-services {
  margin-top: 100px;
  padding: 50px 0;
  background-color: #f3f4f6;
}
.home-services-card {
  width: 100%;
  height: 250px;
  overflow: hidden;
  border: 1px solid #fff;
  position: relative;
}
.home-services-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-services-card-body {
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;
  position: absolute;
  background-color: #1f3d4738;
  backdrop-filter: blur(5px);
  color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.home-services-card:hover .home-services-card-body {
  right: 0;
}
.home-card-btn {
  background: #8fabba;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: capitalize;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
}
.home-card-btn a{
  text-decoration: none;
  color: #fff;
}
.band-module {
  margin: 100px 0;
  overflow: hidden;
}
.band-module-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.band-module-right h2 {
  font-weight: 400;
  font-size: 25px;
}
.band-module-right p {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 200;
}
.band-module-right a {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111;
  border: 1px solid lightgray;
  padding: 5px 15px;
  border-radius: 50px;
}

/* reviews */
.reviews {
  margin-top: 50px;
  background-color: #f3f4f6;
  padding: 70px 0;
}
.reviews .review-left h2{
  font-weight: 400;
  font-size: 25px;
} 
.reviews .review-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.reviews .review-left ul{
  list-style: none;
  display: flex;
  margin: 0;
  padding:0;
  gap: 5px;
}
.reviews .review-left ul li{
  background-color: #00b67a ;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.reviews .review-left p span{
  text-decoration: underline lightgray;
}
.slidercard{
  padding: 0px 10px;
}
.slidercard ul{
  display: flex;
  list-style: none;
  gap: 5px;
  margin-bottom:10px;
  padding: 0;
}
.slidercard ul li{
  background-color: #00b67a ;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.slidercard ul li svg{
  font-size: 10px;
}
.slidercard p{
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
}
.slidercard span{
  font-size: 13px;
  line-height: -10px !important;
}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #111 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Company Slider */
.companySlider{
  margin-top: 100px;
  margin-bottom: 100px;
}
.company-heading{
  display: flex;
  justify-content: center;
}
.company-heading h2{
  color: gray;
  font-weight: 100;
  font-family: "Pacifico", cursive;
}
.companySlider img{
  width: 150px;
  /* height: 100px; */
}

.companySlider .slick-slider .slick-list .slick-track{
  display: flex;
  align-items: center;
} 

@media screen and (max-width:568px){
  .video {
    object-fit: cover;
    width: 100%;
    height: 50vh;
    z-index: -1;
    filter: brightness(30%);
  }
  .home-text {
    padding: 100px 30px;
    z-index: 99;
}
  .banner-text h1 {
    font-size:35px;
    font-weight: 500;
    margin: 0;
    color: #fff;
  }
  .banner-text p {
    font-size: 16px;
    word-spacing: 0.5px;
    letter-spacing: 1px;
    font-weight: 300;
    color: #fff;
}
}